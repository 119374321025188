//

//! Imports

//* Data

import data from "../public/data.js"

let movies = data.movies
let variables = data.variables


//* Firebase

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword } from 'firebase/auth'
import { getFirestore, collection, doc, updateDoc, getDoc, setDoc, getDocs } from 'firebase/firestore'
import { getStorage, ref, uploadBytes } from 'firebase/storage'

const firebaseApp = initializeApp({
     apiKey: "AIzaSyAZXxmrVNvXYqByFJ0voPx3tlpb37Asvgw",
     authDomain: "what2whatch-7b404.firebaseapp.com",
     projectId: "what2whatch-7b404",
     storageBucket: "what2whatch-7b404.appspot.com",
     messagingSenderId: "382579887050",
     appId: "1:382579887050:web:800e6a05c27f13ec349d33",
     measurementId: "G-LPLEWEN0EQ"
})

const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp, "gs://what2whatch-7b404.appspot.com")
/* 
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

// App check
const appCheck = initializeAppCheck(firebaseApp, {
     provider: new ReCaptchaV3Provider('6Lfqo_QoAAAAAP7e-MHo9QiEGEcjt5Do12jFgKbw'),
     //html key: 6Lfqo_QoAAAAAMcVNJPOt5CPhE9YD1uYYgXoXoCu
     isTokenAutoRefreshEnabled: true,
}) */


//! Dupes

//* Send feedback to Firestore

async function sendFeedback(uid) {
     const textareaQ = document.getElementById('textareaQ')
     const feedbackText = textareaQ.value
     const feedbackCol = collection(firestore, "feedback")
     const userFeedbackDoc = doc(feedbackCol, uid)
     let tickets = 0
     try {
          let doc = await getDoc(userFeedbackDoc)
          if(doc.exists()) {
               if(doc.data().tickets) {
                    tickets = parseInt(doc.data().tickets, 10)
               }
               const updatedTickets = tickets + 1
               const feedbackKey =  "feedback-" + updatedTickets.toString()
               const feedback = {
                    [feedbackKey]: feedbackText,
                    tickets: updatedTickets,
               }
               await updateDoc(userFeedbackDoc, feedback)
          }
     }
     catch (error) {console.log(error)} //todo/ handle error UI (not just this one)
}


//* Get date

function getDate() {
     let today = new Date()
     let year = today.getUTCFullYear()
     let month = today.getUTCMonth() + 1 // 0 indexed
     let day = today.getUTCDate()
     return `${day}/${month}/${year}`
}


//! Export

export {
     //* Local
     // data
     movies, variables,
     // dupes
     sendFeedback, getDate,

     //* Firebase
     // authentication
     auth, onAuthStateChanged, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword,
     // firestore
     firestore, collection, doc, updateDoc, getDoc, setDoc, getDocs,
     // storage
     storage, ref, uploadBytes
}